@import "variables";

@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}


@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-LightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}


@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-ExtraLight.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-ExtraLightItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}


@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}


@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('@{image_root}/fonts/barlow/Barlow-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

