@charset "utf-8";
@import "barlow";
@import "theme_base";

html,body{
    background-color: @bg_color_default;
    color: @text_color_default;
    --bg-color: @bg_color_default;
    --bg-color-transparent: @bg_color_default_transparent;
    --bg-color-grayer: @bg_color2_default;
    --bg-color-grayer2: @bg_color3_default;
    --txt-color: @text_color_default;
    --txt-color-light: @text_color_default;
    --txt-color-dakr: @text_color_dark;
    --title-color: @text_color2_default;
    --border-color: @text_color_default;
    --control-line-color: @control_line_color;

    a{
        color: @text_color_default;
    }
}




body.dark, html:has(body.dark) {
    background-color: @bg_color_dark;
    color: @text_color_dark;
    --bg-color: @bg_color_dark;
    --bg-color-transparent: @bg_color_dark_transparent;
    --bg-color-grayer: @bg_color2_dark;
    --bg-color-grayer2: @bg_color3_dark;
    --txt-color: @text_color_dark;
    --title-color: @text_color2_dark;
    --border-color: @text_color_dark;
    --control-line-color: @control_line_color_dark;


    a{
        color: @text_color_dark;
    }
}