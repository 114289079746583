html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin:0; padding:0; border:0; font-size:100%; font:inherit; vertical-align:baseline; }
body { font:normal 16px/1.5 "Barlow", "Roboto", "PingFang SC", Helvetica,"微软雅黑", sans-serif; background:#fff; color:#333; -webkit-font-smoothing:antialiased; -webkit-text-size-adjust:100%; }
h1, h2, h3, h4, h5, h6 { font-size:100%; font-weight:normal }
address, cite, dfn, em, var { font-style:normal; }
code, kbd, pre, samp { font-family:"courier new", courier, monospace; }
small { font-size:12px; }
ul, ol { list-style:none; }
a { text-decoration:none; color:#0069ca; }
a:hover { text-decoration:none; color:#f60; }
sup { vertical-align:text-top; }
sub { vertical-align:text-bottom; }
legend { color:#000; }
fieldset, img { border:0; }
button, input, select, textarea { font-size:100%; }
table { border-collapse:collapse; border-spacing:0; }
select { vertical-align:middle; }
input::-ms-clear { display: none; }
.clr, .clear { clear:both; height:0; overflow:hidden; }
.clr_after {  height: 1%;  zoom: 1  }
.clr_after:after {  display: block; visibility: hidden; height: 0; clear: both; content: "."; }
*{
    box-sizing: border-box;
}